import React from "react"
import Base from "../../components/templates/Base"

import LoginForm from "../../components/Login/Login"
import styled from "styled-components"
import ThemeDefault from "../../components/theme/ThemeDefault"
import device from "../../components/theme/MediaQueries"
import Tick from "../../assets/images/icons/tick.svg";
import Point from "../../assets/images/price-stand-point.svg"
import { Link } from "gatsby"


const LoadedLabs = ({ location }) => {
	return (
		<Base>
			

			<HeroSectionComponent bgColour={'#638cf7'}> 
				<div className="inner-div wrapper hero-inner">
					<div className="title-col">
						<div className="hero-title"><h1>LoadedLabs</h1></div>
					</div>
					<div className="title-col">
						<div className="hero-text">Training, education and inspiration for the hospitality industry.</div>
					</div>
				</div>
			</HeroSectionComponent>

      <PageSectionComponent>
        <div className="inner-div wrapper">

					<div className="c-columns-2 c-columns-l-1 c-columns-m-1 c-columns-gap-l">
						
					<Card cssClass="card dark">

						<div className="card-content">
							
							<div className="title">
								<h2>Show Me The Money</h2>
							</div>
							

							<div className="text">
								Our paid training and implementation options
							</div>
							
							<div className="items">
								<div className="feature" >
									<div className="tick"><Tick /></div>
									<div className="feature-text">We do the setup for you</div>
								</div>
								<div className="feature" >
									<div className="tick"><Tick /></div>
									<div className="feature-text">We help you do the setup and implementation</div>
								</div>
								<div className="feature" >
									<div className="tick"><Tick /></div>
									<div className="feature-text">One on One training</div>
								</div>
								<div className="feature" >
									<div className="tick"><Tick /></div>
									<div className="feature-text">High performance hospitality business coaching</div>
								</div>
							</div>
							
							<div className="button-wrap">
								<Link to='/loaded-labs/show-me-the-money'>
									<div className="button open">
										Learn More
									</div>
								</Link>
							</div>
							

						</div>

					</Card>

						<Card cssClass="card light">

							<div className="card-content">
								
								<div className="title">
									<h2>On the house</h2>
								</div>
								
								<div className="text">
									Check out all of our great free training options
								</div>
								
								<div className="items">
									<div className="feature" >
										<div className="tick"><Tick /></div>
										<div className="feature-text">Live webinars with our community</div>
									</div>
									<div className="feature" >
										<div className="tick"><Tick /></div>
										<div className="feature-text">Interviews with industry experts</div>
									</div>
									<div className="feature" >
										<div className="tick"><Tick /></div>
										<div className="feature-text">Case Studies of leading industry operators</div>
									</div>
									<div className="feature" >
										<div className="tick"><Tick /></div>
										<div className="feature-text">Help Articles, videos and more</div>
									</div>
								</div>
								
								<div className="button-wrap">
									<Link to='/loaded-labs/on-the-house' >
										<div className="button open">
											Learn More
										</div>
									</Link>
								</div>

							</div>

							<PointWrapper className="man-2">
								<Point />
							</PointWrapper>

						</Card>


					</div>

				</div>
      </PageSectionComponent>
		</Base>
	)
}

export default LoadedLabs

const PointWrapper = styled.div`
	position: absolute;
	width: 170px;
	left: 0;
	bottom: 0;
	transform: translate(-150px,0px);

	@media ${device.MXlg} {
		display: none;
	}	
`

const Card = styled.div.attrs((props) => ({
  className: props.cssClass,
}))`
  
	position: relative;
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.35);
	border-radius: 8px;
	height: 100%;
	justify-content: space-between;
	display: flex;
	flex-direction: column;
	
	.small-text {
		font-size: 11px;
		margin-left: 2px;
		letter-spacing: 0;
	}
		
	.tierTitle,
	.tierPrice,
	.tierText,
	.tierButton {
		justify-content: center;
		display: flex;
		flex-direction: column;
		text-align: center;
		margin-bottom: 16px;
	}

	.tierTitle {
		text-align: center;
		font-family:  ${ThemeDefault.fontSecondary};
		font-size:  ${ThemeDefault.font_4};
		line-height: 1.4;
		letter-spacing: -1px;
		font-weight: 400;
	}

	.feature {
		display: flex;
		flex-direction: row;
		align-items: center;
		line-height: 1.2;
		margin: 8px 0;
		padding: 8px;
		position: relative;


		&:after {
			content: '';
			position: absolute;
			border-bottom: 1px solid rgba(0, 0, 0, 0.2);
			width: 80%;
			bottom: -4px;
			left: 50%; 
			transform: translateX(-50%);
		}

		&:last-child {
			&:after {
				display: none;
			}
		}
	}

	.tick {
		color: ${ThemeDefault.blue};
		width: 16px; 
		margin-right: 8px;
		flex-shrink: 0;
		flex-grow: 0;
	}

	.tierPrice {
		.price {
			font-size: ${ThemeDefault.font_6};
			letter-spacing: 1px;
			line-height: 1;
		}
		.currency {
			padding-top: 4px;
			margin-left: 2px;
		}
	}

	& .feature {
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 1.2;
    margin: 8px 0;
    padding: 8px;
    position: relative;

  }

  /* .tick {
    color: ${ThemeDefault.blue};
    width: 16px; 
    margin-right: 16px;
    flex-shrink: 0;
    flex-grow: 0;
  } */


	& .card-content {
		padding: 6.4rem 8rem;

		@media ${device.MXmd} {
			padding: 3.2rem;
		}

		@media ${device.MXsm} {
			padding: 3.2rem 1.6rem;
		}

		& .feature-text {
      font-size: ${ThemeDefault.font_2};
		}
		
		& .items {
			margin-bottom: 3.2rem;
			padding: 0 1.6rem;

			@media ${device.MXmd} {
				padding: 0;	
			}
		}

		& .text {
      font-size: ${ThemeDefault.font_2};
			margin-bottom: 3.2rem;
			padding: 0 1.6rem;
		}

		& ul {
      font-size: ${ThemeDefault.font_2};
		}
	}

	&.light {
		background-color: #f1f0ed;
	}
	&.dark {
		background-color: #d4d4d2;
	}


  .title {

    h2 {
      font-family: ${ThemeDefault.fontSecondary};
      font-size: ${ThemeDefault.font_4};
      font-weight: 400;
      letter-spacing: -1px;
      margin-bottom: 32px;
			text-align: center;
    }

  }

  .button-wrap {
    margin-top: 8px;
    /* margin-bottom: 16px; */
		text-align: center;

    .button {
      display: inline-block;
      padding: 8px 16px;
      background-color: ${ThemeDefault.blue};
      border: 1px solid ${ThemeDefault.blue};
      color: ${ThemeDefault.white};
      letter-spacing: 1px;
      font-size: ${ThemeDefault.font__2};
    }
  }
` 

const PageSectionComponent = styled.section.attrs((props) => ({
  className: props.cssClass,
}))`

	& .col {

		& h2 {
			text-align: center;
			font-family: ${ThemeDefault.fontSecondary};
			color: ${ThemeDefault.black};
		}

		&.light{

		}
		&.dark{

		}

	}

`

const HeroSectionComponent = styled.section.attrs(props => ({
  className: props.cssClass,
}))`
  
  background-color: ${(props) => props.bgColour};
  margin-top: 4px;

  & .hero-inner {
    
		
		&.inner-div {
			padding: 0 !important;
      padding-bottom: 0 !important;
			
      & .gatsby-image-wrapper {
				height: 100%;
      }
    }
		
    flex-direction: row;
    display: flex;
		align-items: center;
    width: 100%;

    @media ${device.MXsm} {
      flex-direction: column;
      align-items: flex-start;
    }

    h1 {
      font-size: ${ThemeDefault.font_6};
      line-height: 1.2;
      letter-spacing: -1px;
      margin-bottom: 16px;
      text-align: left;
      font-weight: 500;

      p {
        margin-bottom: 20px;
      }
    }

    &>div{
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      align-items: flex-start;
    }
    
    .spacer {
      margin-bottom: 64px;

      @media ${device.MXmd} {
        margin-bottom: 32px;
      }
    }

    .title-col {
      padding-top: calc(clamp(3.2rem,1.28rem + 4vw,6.4rem) + 24px);
      padding-left: calc(24px + 24px);
      padding-right: calc(24px + 24px);
      padding-bottom: calc(clamp(3.2rem,1.28rem + 4vw,6.4rem) + 24px);
      width: 50%;

      @media ${device.MXmd} {
        padding: calc(clamp(3.2rem,1.28rem + 4vw,6.4rem) + 24px) 24px;
        width: 60%;
      }
      @media ${device.MXsm} {
        width: 100%;
      }
    }

    .hero-title {
			& h1 {
				font-family: ${ThemeDefault.fontPrimary};
				color: ${ThemeDefault.white};
				margin: 0;
				font-size: 7rem;
				letter-spacing: -6px;
			}
		}

    .hero-text {
      text-align: left;
      font-size: var(--font-3);
      padding-right: 64px;
			font-family: ${ThemeDefault.fontSecondary};
			color: ${ThemeDefault.white};

      @media ${device.MXmd} {
        padding-right: 32px;
      }
      @media ${device.MXsm} {
        padding-right: 0;
      }      
    }

    .button-text {
      text-align: left;
      font-weight: 700;
      color: ${ThemeDefault.black};
      /* color: ${ThemeDefault.blue}; */

      @media ${device.MXmd} {
        width: 100%;
        margin-top: 8px;
      }
    }

    .hero-button-row {
      flex-direction: row;
      display: flex;
      align-items: center;
      
      @media ${device.MXmd} {
        flex-wrap: wrap;
      }
      
      p {
        margin: 0;
      }
      
    }

    .image-col {
      width: 50%;
      padding-top: 0px;
      padding-left: 0px;
      padding-right: 0px;
      padding-bottom: 0px;
      text-align: left;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      
			& picture,
			& img {
				object-fit: ${props => props.imageColContain ? 'contain !important' : 'cover !important'}
			}

      @media ${device.MXmd} {
        width: 40%;
      }

      @media ${device.MXsm} {
        width: 100%;
      }

      @media ${device.MXxs} {
        width: 100%;
      }
      
    }

  }
`